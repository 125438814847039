import React, { Component } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import API from "../../utils/API";
import { Spin } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
const lang = localStorage.getItem("lang") || "en";

let loopData = [];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: [],
      properties: [],
      total_agencies: 0,
      rents: 0,
      sales: 0,
      agents: 0,
      purposebymonthArr: [],
      isLoading: true,
      properties_chart: [],
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });

    API.post("/dashboard")
      .then((response) => {
        let i;

        let data =
          lang == "en"
            ? response.data.properties_chart_en
            : response.data.properties_chart_ar;
        for (i = 0; i < data.length; i++) {
          var loopObject = {};
          loopObject["name"] = data[i].name;
          loopObject["for_rent"] =
            data[i].for_rent.length > 0
              ? data[i].for_rent[0].property_count
              : 0;
          loopObject["for_sale"] =
            data[i].for_sale.length > 0
              ? data[i].for_sale[0].property_count
              : 0;

          loopData.push(loopObject);
        }
        this.setState({ properties_chart: loopData });
        loopData = [];
        this.setState({
          agencies: response.data.agencies,
          total_agencies: response.data.total_agencies,
          rents: response.data.rents,
          sales: response.data.sales,
          purposebymonthArr: response.data.bymonth,
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ isLoading: false }));
  }
  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: "#edf2f6" }}>
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>
              {lang == "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {lang == "en" ? "Dashboard" : "لوحة البيانات"}
            </Breadcrumb.Item>
          </Breadcrumb>
        {!this.state.isLoading ? (
          <div
            className="container-fluid"
            style={{ backgroundColor: "#edf2f6" }}
          >
        
            <div className="row">
              <div className="col-xl-4 col-sm-6">
                <div className="card align-items-center material-blue">
                  <div className="card-body d-flex align-items-center justify-content-between">
                    <div className="card-data me-2 text-center">
                      <h5>
                        {" "}
                        {lang == "en" ? "Propeties for Sale" : "عقارات للبيع"}
                      </h5>
                      <h2 className="fs-40 font-w600">{this.state.sales}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="card align-items-center  material-green">
                  <div className="card-body d-flex align-items-center justify-content-between">
                    <div className="card-data me-2 text-center">
                      <h5>
                        {lang == "en" ? "Propeties for Rent" : "عقارات للايجار"}
                      </h5>
                      <h2 className="fs-40 font-w600">{this.state.rents}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="card align-items-center  material-red">
                  <div className="card-body d-flex align-items-center justify-content-between ">
                    <div className="card-data text-center">
                      <h5>
                        {" "}
                        {lang == "en" ? "Total Agencies" : "عدد الشركات"}
                      </h5>
                      <h2 className="fs-40 font-w600 text-center">
                        {this.state.agencies}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-xxl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card" id="responsive-map">
                      <div className="card-header border-0">
                        <h4 className="fs-20">
                          {" "}
                          {lang == "en" ? "Propeties chart" : " مخطط العقارات"}
                        </h4>
                      </div>
                      <div className="card-body">
                        {this.state.properties_chart.length > 0 ? (
                          <BarChart
                            className="barChart"
                            width={1400}
                            height={400}
                            data={this.state.properties_chart}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="for_rent"
                              barSize={35}
                              fill="#8884d8"
                            />
                            <Bar
                              dataKey="for_sale"
                              barSize={35}
                              fill="#82ca9d"
                            />
                          </BarChart>
                        ) : (
                          <>
                            {lang == "en"
                              ? "No Data to display"
                              : "لاتوجد بيانات للعرض"}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "500px",
              verticalAlign: "middle",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default AdminView;
