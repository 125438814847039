import React, { Component } from "react";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import API from "../../../utils/API";

import "antd/dist/antd.css";
import { Table, Breadcrumb } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { store } from "../../../store";
import { basefont } from "../../../Components/BaseFont";
//import autoTable from "jspdf-autotable";
import autoTable from "jspdf-autotable";


import { Watch } from  'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const lang = localStorage.getItem("lang") || "en";

class AgencyLeadsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      agencies:[],
      agency_id:0,
      locations: [],
      filterLocation: [],
      dateFrom: "",
      dateTo: "",
      dateFromre: "",
      dateTore: "",
      showData: "none",
      selectedLocations: [],
      locationName: "All",
      isLoading:false
    };
  }

  componentDidMount() {
    API.get("/filterpublishLocation")
      .then((response) => {
        this.setState({
          filterLocation:
            lang == "en" ? response.data.locations : response.data.locations_ar,
        });
      })
      .catch((e) => console.log("error"));
 // list agencies
      API.get("/listagency")
      .then(response =>{
        this.setState({agencies: response.data.agencies})
      })

      .catch(e=>console.log('error'))
  }

  handleAgency =(e) =>{
    e.preventDefault();
    this.setState({agency_id:e.target.value}) 
}

  printDocument() {
    //const input = document.getElementById('divToPrint');
    let currentDate = new Date()
    // const doc = new jsPDF();
    let doc = new jsPDF();
    // doc.addFileToVFS(
    //   "(A) Arslan Wessam A (A) Arslan Wessam A-normal.ttf",
    //   basefont
    // );
    // doc.addFont(
    //   "(A) Arslan Wessam A (A) Arslan Wessam A-normal.ttf",
    //   "(A) Arslan Wessam A (A) Arslan Wessam A",
    //   "normal"
    // );
    // doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    

    doc.autoTable({ html: '#my-table' })
    doc.save("leads-"+currentDate+'.pdf')
    

    //get table html
   // const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    //var html = htmlToPdfmake(pdfTable.innerHTML);

    // autoTable(doc, "#basic-table");
    // doc.save("table.pdf");

    // doc.html(" نعم ، هذا يعمل يا الكريم", 10, 10);
    // doc.save("hey");

    // const documentDefinition = { content: html };
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // pdfMake.createPdf(documentDefinition).open();
  }

  handleDateFrom = (e) => {
    this.setState({ dateFrom: e });
  };

  handleDateTo = (e) => {
    this.setState({ dateTo: e });
  };

  handleSearch = (e) => {
    const data = {
      agency_id: this.state.agency_id,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
    };

  this.setState({isLoading:true})

    //return

    API.post("/agencyleadsreport", data)
      .then(async (response) => {
        this.setState({
          showData: "",
          leads: response.data.leads,
          isLoading:false
        });
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const userdata = store.getState((state) => state);
    let user = userdata.auth.user;
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";

    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>
              {lang == "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Leads To PDF</Breadcrumb.Item>
          </Breadcrumb>
          <div className="row mb-4">
            <div className="card">
              <div className="col-md-12">
                <div className="card-body">
                  <div className="row mt-10">
                    <div className="col-md-4">
                      <label>Company</label>
                      <select  onChange={this.handleAgency} className="form-select select2-container--default" style={{ height: '2.75rem' }} aria-label="form-select" required>
                      <option  value="0" >Any</option>
                      {this.state.agencies.map((agency) =>(
                      <option key={agency.id} value={agency.id}>{lang=="en"?agency.name_en:agency.name_ar}</option>
                      ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label>{lang == "en" ? "Date From" : "من تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateFrom}
                        onSelect={this.handleDateFrom} //when day is clicked
                        onChange={this.handleDateFrom} //only when value has changed
                      />
                    </div>
                    <div className="col-md-3">
                      <label> {lang == "en" ? "Date To" : " الي تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateTo}
                        onSelect={this.handleDateTo} //when day is clicked
                        onChange={this.handleDateTo} //only when value has changed
                        format="yyyy-MM-dd"
                      />
                    </div>
                    <div className="col-md-1 mt-4">
                      <label style={{ marginTop: "30px" }}></label>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={this.handleSearch}
                      >
                        {lang == "en" ? "Search" : "ابحث"}
                      </button>
                    </div>
                  </div>
                  <div className="row mt-2 mb-3"></div>
                  <div className="mt-5" />
                  <hr />

                  {this.state.isLoading?
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                      <Watch
                        height="80"
                        width="80"
                        radius="48"
                        color="grey"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                  </div>:
                  <div style={{ display: this.state.showData }}>
                    <div>
                      <div className="col-md-3 mt-4">
                        <label style={{ marginTop: "30px" }}></label>
                        <button
                          type="button"
                          className="btn  btn-secondary"
                          onClick={this.printDocument}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-file-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                          </svg>{" "}
                          {lang == "en" ? "Generate pdf" : "طباعة"}
                        </button>
                      </div>
                    </div>
                    <div id="divToPrint">
                   
                      <div class="table-responsive">
                        <table class="table" id="my-table">
                          <thead>
                            <tr>
                              <th scope="col">Company</th>
                              <th scope="col">Property No</th>
                              <th scope="col">Property Title</th>
                              <th scope="col">Location</th>
                              <th scope="col">Agent Name</th>
                              <th scope="col">Clicks</th>
                              <th scope="col">Calls</th>
                              <th scope="col">Whatsapp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.leads.map((lead) => (
                              <tr>
                                  <td>
                                  {lead.agency_name }
                                </td>
                                <td>
                                  {lead.id }
                                </td>
                                <td>
                                  {lang == "en" ? lead.title_en : lead.title_ar}
                                </td>
                                <td>
                                  {lang == "en"
                                    ? lead.emirate_en + " - " + lead.area_en
                                    : lead.emirate_ar + " - " + lead.area_ar}
                                </td>
                                <td>
                                  {lead.agent_name }
                                </td>
                                <td>
                                  {lead.viewed.length > 0
                                    ? lead.viewed.map((v) => (
                                        <>{v.property_count}</>
                                      ))
                                    : "-"}
                                </td>
                                <td>
                                  {lead.call.length > 0
                                    ? lead.call.map((c) => (
                                        <>{c.property_count}</>
                                      ))
                                    : "-"}
                                </td>
                                <td>
                                  {lead.whatsapp.length > 0
                                    ? lead.whatsapp.map((w) => (
                                        <>{w.property_count}</>
                                      ))
                                    : "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
        }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgencyLeadsReport;
