import React, { Component } from 'react'
import { connect } from 'react-redux';
import cookie from 'js-cookie'
import ClipLoader from "react-spinners/ClipLoader";
import i18next from 'i18next'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import API from '../../utils/API';
import { Layout, Menu, Avatar, Dropdown, Space, Button, Drawer } from "antd";

const { Header, Content, Sider, Footer } = Layout;

export class Login extends Component {
    constructor(props)
    {
        super(props);
        this.state= {
            email:'',
            password: '',
            errors:{},
            isLoading:false,
            check_status:false,
            input: {},
            validationerrors: {},
        }
    }

    componentDidMount(){

        
        if(Cookies.get("u") && Cookies.get("p") && Cookies.get("check_status")){
        const namename= Cookies.get("u")
        const password= Cookies.get("p")
        let input = {}
        input["email"] = atob(namename)
        input["password"] = atob(password)

        this.setState({email:atob(namename),password:atob(password),check_status:Cookies.get("check_status"),input:input})
   
        }
    }
    handleInput = (e) =>{
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;

        this.setState({[name]:value,input:input})
    }

    handleForm = async(e) =>{
        e.preventDefault();
        const data = {email:this.state.email,password:this.state.password}
     
        if(this.validate()){
        this.setState({isLoading:true})

       await API.post("/auth/login",data)
        .then(res => { 
           if(this.state.check_status){
                var email = this.state.email;
                var password = this.state.password;
                var encodedEmail = btoa(email);
                var encodedPassword = btoa(password);
                cookie.set("u",encodedEmail);
                cookie.set("p",encodedPassword);
                cookie.set("check_status",true)
            }else{
                cookie.remove("u")
                cookie.remove("p")
                cookie.remove("check_status")

            }
        if(res?.data)
        this.props.setCredentials(res.data)
  
      
    })
    .catch(error => {
        if (!error.response) {
            // network error
            this.setState({errors:JSON.stringify({"errors":"Error: Network Error"}),isLoading:false})
        } else {
            this.setState({errors:error.response.data,isLoading:false})
        }
      })
    }
    }

    validate(){
        let input = this.state.input;
        let validationerrors = {};
        let isValid = true;    
    
         
         if (!input["email"]) {
          isValid = false;
          validationerrors["email"] = "Please enter your email Address.";
         }
    

          if (!input["password"]) {
            isValid = false;
            validationerrors["password"] = "Please enter your password .";
          }

   



        if (typeof input["email"] !== "undefined") {
            
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(input["email"])) {
            isValid = false;
            validationerrors["email"] = "Please enter valid email address.";
          }
        }
    
   
    
        this.setState({
            validationerrors: validationerrors
        });
    
        return isValid;
    }

    buttonRender =() => {
        if(this.state.isLoading){
            return <ClipLoader color={"blue"} loading={true}  size={30} />
        }
        return (
            <button type="submit" className="btn btn-primary btn-block">{i18next.t("signin")}</button>
        )
    }
    handleRememberMe =(e)=>{

        this.setState({check_status:!this.state.check_status})
    }
    render() {
        const error= this.state.errors
        const lang =localStorage.getItem("lang") || "en";
        return (
            <div dir={lang =="ar"?"rtl":"ltr"}>
            <body className="vh-100">
    <div className="authincation h-100">
        <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3">
                                        <Link to="/"><img src="images/conpanylogo/findproperties.png" style={{height:'100px'}} alt="Findproperties" /></Link>
                                    </div>
                                    <h4 className="text-center mb-4">{i18next.t("signintitle")}</h4>
                                    <h5 className="text-center mb-4"> {error ? <p className="text-red">{error.errors}</p> :("")}</h5>
                                    <form onSubmit={this.handleForm}>
                                        <div className="mb-3">
                                            <label className="mb-1"><strong>{i18next.t("email")}</strong></label>
                                            <input type="email" className="form-control" name="email" value={this.state.email}  onChange={this.handleInput} />
                                            <div className="text-danger">{this.state.validationerrors.email}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="mb-1"><strong>{i18next.t("password")}</strong></label>
                                            <input type="password" className="form-control" name="password" value={this.state.password}  onChange={this.handleInput} />
                                            <div className="text-danger">{this.state.validationerrors.password}</div>
                                        </div>
                                        <div className="row d-flex justify-content-between mt-4 mb-2">
                                            <div className="mb-3">
                                               <div className="form-check custom-checkbox ms-1">
                                                    <input type="checkbox" onChange={this.handleRememberMe} checked={this.state.check_status} className="form-check-input" id="basic_checkbox_1" style={{float:lang=="en"?'left':'right',marginLeft: '-1.5em'}} />
                                                    <label className="form-check-label" for="basic_checkbox_1" style={{marginRight: '2.3125rem',marginTop: '0.1875rem'}}> {i18next.t("remember")} </label>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Link to="/forgot-password">{i18next.t("forgetpassword")}</Link>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {this.buttonRender()}
                                        </div>      
                                    </form>
                                    <div className="new-account mt-3">
                                        <p>{i18next.t("faceproblem")} <Link className="text-primary" to="/tech-support">{i18next.t("technicalsupport")}</Link></p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
         
            </div>
       
        </div>
    </div>
</body>

        </div>
        )
    }
}
const mapDispatchToProps = dispatch =>{
    return {
        setCredentials: (data) => dispatch({type: "SET_LOGIN",payload:data})
    }
  }
  export default connect(null,mapDispatchToProps)(Login)